import { render, staticRenderFns } from "./videoComment.vue?vue&type=template&id=71f819ce&scoped=true&"
import script from "./videoComment.vue?vue&type=script&lang=js&"
export * from "./videoComment.vue?vue&type=script&lang=js&"
import style0 from "./videoComment.vue?vue&type=style&index=0&id=71f819ce&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71f819ce",
  null
  
)

export default component.exports