var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"dkzc-box"},[_c('div',{staticClass:"left"},[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/index-syllabus' }}},[_vm._v("考纲教材")]),_c('el-breadcrumb-item',{attrs:{"to":{
            path: '/index-syllabus-videoLecture',
            query: { book_uid: _vm.$route.query.book_uid },
          }}},[_vm._v("视频讲座")]),_c('el-breadcrumb-item',[_vm._v("详情")])],1),_c('video',{ref:"videoPlayer",staticStyle:{"width":"890px","height":"560px"},attrs:{"controls":"","autoplay":"","poster":_vm.detail.avatar,"src":_vm.detail.path,"controlslist":"nodownload"}}),_c('div',{staticClass:"content"},[_c('span',[_vm._v(_vm._s(_vm.detail.video_name))])]),_c('div',{staticClass:"titleName"},[_vm._v(" "+_vm._s(_vm.detail.subspecialty_text)+" ")]),_c('div',{staticClass:"time"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.detail.browse_num > 10000 ? (_vm.detail.browse_num - (_vm.detail.browse_num % 1000)) / 10000 + "万" : _vm.detail.browse_num))]),_c('span',{staticClass:"browse"},[_vm._v("浏览")])]),_c('span',[_vm._v(_vm._s(_vm._f("formaTime")(_vm.detail.add_time)))])]),_c('div',{staticClass:"icons",staticStyle:{"display":"none"}},[_c('div',{staticClass:"favoritesNumber"},[_c('i',{staticClass:"iconfont",class:{
              shoucanghover: _vm.detail.collect_status,
              shoucang: !_vm.detail.collect_status,
            },on:{"click":function($event){return _vm.collectionFn(_vm.detail.collect_status)}}}),_c('span',[_vm._v(_vm._s(_vm.detail.collect_num || 0))])]),_c('div',{staticClass:"like"},[_c('i',{staticClass:"iconfont",class:{
              zan1: _vm.detail.praise_status,
              iconzan2: !_vm.detail.praise_status,
            },on:{"click":_vm.articlePraiseFn}}),_c('span',[_vm._v(_vm._s(_vm.detail.praise_num || 0))])]),_c('span',{staticClass:"share"},[_vm._v("分享：")]),_c('share',{attrs:{"config":_vm.config}})],1),_c('div',{staticClass:"numberComments",staticStyle:{"display":"none"}},[_c('span',{staticClass:"number"},[_vm._v(_vm._s(_vm.detail.comment.length || 0))]),_c('span',[_vm._v("条评论")])]),_c('comment',{ref:"comment",staticStyle:{"display":"none"},attrs:{"width":"840","comments":_vm.detail.comment,"discover_id":_vm.$route.query.video_uid},on:{"delete_comment":_vm.delete_comment}})],1),_c('div',{staticClass:"right-group"},[(_vm.hotList.length)?_vm._l((_vm.hotList),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index < 4),expression:"index < 4"}],key:index,staticClass:"group",on:{"click":function($event){return _vm.handleClick(item)}}},[_c('div',{staticClass:"group-left"},[(item.avatar)?_c('div',[_c('img',{staticClass:"information",attrs:{"src":item.avatar}}),_c('div',{staticClass:"play"},[_c('img',{attrs:{"src":_vm.$http.baseURL + '/static/images/web/2x1.png',"alt":""}})])]):_vm._e()]),_c('div',{staticClass:"right"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.video_name))]),_c('div',{staticClass:"site"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(item.author))])]),_c('div',{staticClass:"time"},[_c('span',[_vm._v(_vm._s(item.browse_num > 10000 ? (item.browse_num - (item.browse_num % 1000)) / 10000 + "万" : item.browse_num))]),_c('span',[_vm._v("次观看")])])])])}):_c('div',{staticClass:"null-box"},[_c('img',{attrs:{"src":_vm.$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png',"alt":""}}),_c('div',{staticClass:"title"},[_vm._v("暂无数据")])])],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }