<template>
  <div class="page">
    <div class="dkzc-box">
      <div class="left">
        <!-- 标题 -->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/index-syllabus' }"
            >考纲教材</el-breadcrumb-item
          >
          <el-breadcrumb-item
            :to="{
              path: '/index-syllabus-videoLecture',
              query: { book_uid: $route.query.book_uid },
            }"
            >视频讲座</el-breadcrumb-item
          >
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 视频 -->
        <video
          ref="videoPlayer"
          controls
          autoplay
          :poster="detail.avatar"
          :src="detail.path"
          controlslist="nodownload"
          style="width: 890px; height: 560px"
        ></video>
        <!-- 简介 -->
        <div class="content">
          <span>{{ detail.video_name }}</span>
        </div>
        <!-- 简介时间 -->
        <div class="titleName">
          {{ detail.subspecialty_text }}
          <!-- • {{ detail.author }} -->
        </div>
        <!-- 简介正文 -->
        <div class="time">
          <div>
            <span>{{
              detail.browse_num > 10000
                ? (detail.browse_num - (detail.browse_num % 1000)) / 10000 +
                  "万"
                : detail.browse_num
            }}</span>
            <span class="browse">浏览</span>
          </div>
          <span>{{ detail.add_time | formaTime }}</span>
        </div>
        <!-- 点赞收藏图标区 -->
        <div class="icons" style="display: none">
          <div class="favoritesNumber">
            <i
              @click="collectionFn(detail.collect_status)"
              class="iconfont"
              :class="{
                shoucanghover: detail.collect_status,
                shoucang: !detail.collect_status,
              }"
            ></i>
            <span>{{ detail.collect_num || 0 }}</span>
          </div>
          <div class="like">
            <i
              @click="articlePraiseFn"
              class="iconfont"
              :class="{
                zan1: detail.praise_status,
                iconzan2: !detail.praise_status,
              }"
            ></i>
            <span>{{ detail.praise_num || 0 }}</span>
          </div>
          <span class="share">分享：</span>
          <share :config="config"></share>
          <!-- <img class="weixin" src="@image/weixin.svg" />
                    <img class="qq" src="@image/qq.svg" />
                    <img class="weibo" src="@image/weibo.svg" /> -->
        </div>
        <!-- 评论数 -->
        <div class="numberComments" style="display: none">
          <span class="number">{{ detail.comment.length || 0 }}</span>
          <span>条评论</span>
        </div>
        <comment
          style="display: none"
          ref="comment"
          width="840"
          :comments="detail.comment"
          @delete_comment="delete_comment"
          :discover_id="$route.query.video_uid"
        ></comment>
      </div>
      <!-- 右侧信息区 -->
      <div class="right-group">
        <template v-if="hotList.length">
          <div
            class="group"
            v-show="index < 4"
            v-for="(item, index) in hotList"
            :key="index"
            @click="handleClick(item)"
          >
            <div class="group-left">
              <div v-if="item.avatar">
                <img class="information" :src="item.avatar" />
                <div class="play">
                  <img
                    :src="$http.baseURL + '/static/images/web/2x1.png'"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="right">
              <span class="title">{{ item.video_name }}</span>
              <div class="site">
                <!-- <span>上海红房子医院</span> -->
                <span class="name">{{ item.author }}</span>
              </div>
              <div class="time">
                <span>{{
                  item.browse_num > 10000
                    ? (item.browse_num - (item.browse_num % 1000)) / 10000 +
                      "万"
                    : item.browse_num
                }}</span>
                <span>次观看</span>
              </div>
            </div>
          </div>
        </template>
        <div class="null-box" v-else>
          <img
            :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
            alt=""
          />
          <div class="title">暂无数据</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import comment from "@components/common/videoComment";
import { mapState } from "vuex";
export default {
  components: { comment },
  data() {
    return {
      isShow: false,
      star: false,
      detail: {
        comment: [],
      },
      content: "",
      placeholder: "说说你的看法",
      userItem: "",
      commentLoading: false,
      hotList: [],
      video_uid: 0,
      config: {
        title: "网医-考纲教材-视频讲座-详情",
        url: window.location.href,
        sites: ["wechat", "qq", "weibo"],
        wechatQrcodeTitle: "微信扫一扫：分享",
        wechatQrcodeHelper:
          "<p>扫一扫</p><p>二维码便可将本文分享至朋友圈。</p>",
      },
    };
  },
  async mounted() {
    this.video_uid = this.$route.query.video_uid;
    this.xin.isLoadding(true);
    await this.getBookVideoInfo(this.video_uid);
    await this.getVideo_hot();
    this.xin.isLoadding(false);
  },
  computed: {
    ...mapState("userManagement", ["userInfo"]),
  },
  methods: {
    async getVideo_hot() {
      let res = await this.$newsApi.getVideo_hot({
        video_id: this.video_uid,
      });
      if (res.code == 200) {
        this.hotList = res.data;
      }
    },
    async handleClick(item) {
      this.xin.isLoadding(true);
      await this.getBookVideoInfo(item.video_uid);
      await this.getVideo_hot();
      this.xin.isLoadding(false);
    },
    selectUsre(item) {
      this.userItem = item;
      this.placeholder = "说说你的看法";
      if (this.userItem) {
        this.placeholder = "回复" + this.userItem.doctor.name;
      }
    },
    async getBookVideoInfo(video_uid) {
      let res = await this.$newsApi.getBookVideoInfo({ video_id: video_uid });
      if (res.code == 200) {
        this.detail = res.data;
      }
    },
    // 收藏
    async collectionFn(type) {
      if (this.star) return;
      this.star = true;
      let data = {
        video_id: this.video_uid,
        type: 2,
      };
      let res = await this.$findApi.findCollection(data);
      if (res.code == 200) {
        this.detail.collect_status = !type;
        if (type) {
          this.detail.collect_num -= 1;
        } else {
          this.detail.collect_num += 1;
        }
      }
      this.star = false;
    },
    // 视频点赞
    async articlePraiseFn() {
      if (this.star) return;
      this.star = true;
      let data = {
        like_id: this.video_uid,
        type: 3,
      };
      let res = await this.$findApi.findCommentLike(data);
      if (res.code == 200) {
        if (this.detail.praise_status) {
          this.detail.praise_num -= 1;
        } else {
          this.detail.praise_num += 1;
        }
        this.detail.praise_status = !this.detail.praise_status;
      }
      this.star = false;
    },

    // 删除评论
    async delete_comment(id) {
      let { code } = await this.$findApi.findDelete({
        id,
      });
      if (code == 200) {
        this.detail.comment = this.detail.comment.filter((item) => {
          item.reply = item.reply.filter((it) => {
            return it.id != id;
          });
          return item.id != id;
        });
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      }
    },
  },
  updateTime(e) {
    this.currentTime = e.target.currentTime;
  },
  toPlay() {
    this.$refs.videoPlayer.paused === true
      ? this.$refs.videoPlayer.play()
      : this.$refs.videoPlayer.pause();
  },
  toForward() {
    var time = 10; //单位秒，每次增减10秒
    this.$refs.videoPlayer.volume !== this.$refs.videoPlayer.duration
      ? (this.$refs.videoPlayer.currentTime += time)
      : 1;
  },
  toBack() {
    var time = 10;
    this.$refs.videoPlayer.currentTime !== 0
      ? (this.$refs.videoPlayer.currentTime -= time)
      : 1;
  },
  addVoice() {
    var vol = 0.1;
    this.$refs.videoPlayer.volume !== 1
      ? (this.$refs.videoPlayer.volume += vol)
      : 1;
  },
  decrease() {
    var vol = 0.1;
    this.$refs.videoPlayer.volume !== 0
      ? (this.$refs.videoPlayer.volume -= vol)
      : 1;
  },
};
</script>
<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.iconfont {
  font-size: 24px;
}
video,
textarea {
  &:focus {
    outline: none;
  }
}
.page {
  > .dkzc-box {
    display: flex;
    flex-direction: row;
    width: 1200px;
    margin: 0 auto;
    > .left {
      display: flex;
      flex-direction: column;
      margin-right: 16px;
      width: 890px;
      > .title {
        font-size: 18px;
        color: #333333;
        font-weight: 600;
        margin-right: 10px;
      }
      > .video {
        width: 890px;
        height: 560px;
      }
      > .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        justify-content: space-between;
        color: #333333;
        font-size: 24px;
        img {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
        div {
          display: flex;
          align-items: center;
        }
        .number {
          font-size: 12px;
          color: #8f8f8f;
          margin-left: 3px;
        }
        .praise {
          margin-left: auto;
        }
        .collect {
          margin-left: 20px;
        }
      }
      .titleName {
        margin: 10px 0 20px 0;
        color: #8f8f8f;
      }
      > .time {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 18px;
        color: #8f8f8f;

        > div {
          > .browse {
            margin-right: 20px;
          }
        }
      }
      > .subTitle {
        line-height: 1.8;
        color: #8f8f8f;
        font-size: 18px;
        overflow: hidden;
        /*必须结合的属性,当内容溢出元素框时发生的事情*/
        text-overflow: ellipsis;
        /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
        display: -webkit-box;
        /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
        -webkit-line-clamp: 5;
        /*用来限制在一个块元素显示的文本的行数。*/
        -webkit-box-orient: vertical;
      }
      > .personage {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 30px;
        > img {
          width: 70px;
          height: 70px;
          object-fit: cover;
        }
        > .personage-right {
          display: flex;
          flex-direction: column;
          margin-left: 12px;
          width: 100%;
          > .one {
            display: flex;
            flex-direction: row;
            color: #333333;
            font-size: 24px;
            > .position {
              font-size: 13px;
              color: $--themeColor;
              width: 75px;
              height: 25px;
              border: 1px solid $--themeColor;
              border-radius: 6px;
              text-align: center;
              line-height: 26px;
              margin-left: 12px;
            }
            > .button {
              font-size: 12px;
              color: #ffffff;
              width: 56px;
              height: 25px;
              background: $--themeColor;
              border-radius: 5px;
              text-align: center;
              line-height: 24px;
              margin-left: auto;
            }
          }
          > .two {
            display: flex;
            flex-direction: row;
            color: #8f8f8f;
            font-size: 16px;
            margin-top: 9px;
            > .site {
              margin-right: 20px;
            }
          }
        }
      }
      > .icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #8f8f8f;
        margin-top: 33px;
        cursor: pointer;
        font-size: 24px;
        > .favoritesNumber {
          margin-right: 40px;
          .shoucanghover {
            color: #e60012;
          }
        }
        > .like {
          .zan1 {
            color: #e60012;
          }
        }
        > .share {
          font-size: 16px;
          margin-left: auto;
        }
        > .qq {
          margin: 0 20px;
        }
      }
      > .numberComments {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #333333;
        font-size: 24px;
        margin-top: 30px;
        padding-bottom: 16px;
        box-sizing: border-box;
        border-bottom: 1px solid #f2f2f2;
        > .number {
          font-size: 30px;
          color: #e60012;
          margin-right: 5px;
        }
      }
    }
    > .right-group {
      display: flex;
      flex-direction: column;
      width: 294px;
      height: min-content;
      background: #f2f2f2;
      padding: 15px;
      box-sizing: border-box;
      margin-top: 20px;
      .group {
        margin-bottom: 20px;
        padding-bottom: 20px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        border-bottom: 1px dashed #000;

        &:nth-child(4) {
          border-bottom: none;
        }

        &:last-child {
          margin-bottom: 0;
        }
        > .group-left {
          display: flex;
          > div {
            position: relative;
            width: 100px;
            height: 100px;
            margin-right: 10px;
            .information {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 10px;
            }
            .play {
              width: 30px;
              height: 30px;
              position: absolute;
              top: 50%;
              left: 50%;
              margin-top: -15px;
              margin-left: -15px;
              opacity: 0.7;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        > .right {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          color: #8f8f8f;
          > .title {
            color: #000;
            line-height: 20px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            width: 160px;
          }
          > .site {
            margin-top: 5px;
            > .name {
              // margin-left: 8px;
            }
          }
          > .time {
            margin-top: auto;
          }
        }
      }
    }
  }
}

.zanhover,
.zan1 {
  color: #e60012;
}
</style>
